<template lang="pug">
svg(
  :width="width"
  :height="height"
  viewBox="0 0 5 19"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
)
  path(
    d="M2.5 4C2.89556 4 3.28224 3.8827 3.61114 3.66294C3.94004 3.44318 4.19639 3.13082 4.34776 2.76537C4.49914 2.39992 4.53874 1.99778 4.46157 1.60982C4.3844 1.22186 4.19392 0.865492 3.91421 0.585787C3.63451 0.306082 3.27814 0.115601 2.89018 0.0384303C2.50222 -0.0387401 2.10009 0.000866562 1.73463 0.152242C1.36918 0.303617 1.05682 0.559962 0.837062 0.88886C0.617299 1.21776 0.500001 1.60444 0.500001 2C0.500001 2.53043 0.710715 3.03914 1.08579 3.41421C1.46086 3.78929 1.96957 4 2.5 4ZM2.5 14C2.10444 14 1.71776 14.1173 1.38886 14.3371C1.05996 14.5568 0.803617 14.8692 0.652242 15.2346C0.500867 15.6001 0.46126 16.0022 0.53843 16.3902C0.615601 16.7781 0.806083 17.1345 1.08579 17.4142C1.36549 17.6939 1.72186 17.8844 2.10982 17.9616C2.49778 18.0387 2.89992 17.9991 3.26537 17.8478C3.63082 17.6964 3.94318 17.44 4.16294 17.1111C4.3827 16.7822 4.5 16.3956 4.5 16C4.5 15.4696 4.28929 14.9609 3.91421 14.5858C3.53914 14.2107 3.03043 14 2.5 14ZM2.5 7C2.10444 7 1.71776 7.1173 1.38886 7.33706C1.05996 7.55682 0.803617 7.86918 0.652242 8.23463C0.500867 8.60009 0.46126 9.00222 0.53843 9.39018C0.615601 9.77814 0.806083 10.1345 1.08579 10.4142C1.36549 10.6939 1.72186 10.8844 2.10982 10.9616C2.49778 11.0387 2.89992 10.9991 3.26537 10.8478C3.63082 10.6964 3.94318 10.44 4.16294 10.1111C4.3827 9.78224 4.5 9.39556 4.5 9C4.5 8.46957 4.28929 7.96086 3.91421 7.58579C3.53914 7.21071 3.03043 7 2.5 7Z"
    :fill="color"
  )
</template>

<script>
  export default {
    props: {
      width: {
        type: [Number, String],
        default: 5,
      },
      height: {
        type: [Number, String],
        default: 19,
      },
      color: {
        type: String,
        default: '#505763',
      },
    },
  };
</script>
