<template lang="pug">
.sidebar-device-selector-wrapper(
  :style="{ float: this.float }"
  :class="hasViewText ? 'ml-auto' : ''"
)
  .sidebar-device-selector-current(@click="open" :class="!hasViewText ? 'mx-1' : ''")
    span.sidebar-device-selector-current-view.mx-1(v-if="hasViewText") {{ mobilePreview ? $t('device.mobileView') : $t('device.desktopView') }}
    span.sidebar-device-selector-current-icon(ref="current")
      component(:is="`${currentDevice}-svg`" color="#ABB1C0" :width="16" :height="16")
</template>

<script>
  import { mapState } from 'vuex';

  const DEVICES = { MOBILE: 'mobile', DESKTOP: 'desktop' };
  export default {
    name: 'DeviceSelector',
    props: {
      float: { type: String, default: null },
      hasViewText: { type: Boolean, default: true },
    },
    computed: {
      ...mapState(['mobilePreview', 'deviceSelectorList']),
      currentDevice() {
        return this.mobilePreview ? DEVICES.MOBILE : DEVICES.DESKTOP;
      },
    },
    methods: {
      open() {
        const { top, left } = this.$refs.current.getBoundingClientRect();

        this.deviceSelectorList.position.top = top;
        this.deviceSelectorList.position.left = left;
        this.deviceSelectorList.show = true;
      },
    },
  };
</script>
